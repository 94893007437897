import styled from 'styled-components';
import imgBanner from 'assets/imgs/common/private.png';
import Background from './Background';
import Responsive from './Responsive';

const StyledBackground = styled(Background)`
  height: 120px;

  @media (min-width: 480px) {
    height: 170px;
  }

  @media (min-width: 767px) {
    height: 280px;
  }
`;

const PrivateBlock = styled(Responsive)`
  padding: 2rem 2rem;

  .index-title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 60px;
  }

  .index {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .content {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    .index-title {
      font-size: 24px;
      margin-bottom: 50px;
    }

    .index {
      font-size: 16px;
    }

    .content {
      font-size: 14px;
    }
  }

  @media (max-width: 479px) {
    .index-title {
      font-size: 22px;
      margin-bottom: 40px;
    }

    .index {
      font-size: 14px;
    }

    .content {
      font-size: 12px;
    }
  }
`;

const Private = () => {
  return (
    <>
      {/* <StyledBackground img={imgBanner} /> */}
      <PrivateBlock>
        <div className="index-title">개인정보처리방침</div>
        <div className="index">제1조 (목적)</div>
        <div className="content">
          이 약관은 (주)씨드앤(이하“회사”)가 제공하는 위치기반서비스와 관련하여
          회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을
          규정함을 목적으로 합니다.
        </div>
        <div className="index">제2조 (약관 외 준칙)</div>
        <div className="content">
          이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률,
          정보 통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법,
          전기통신사업법 등 관계법령과 회사의 이용약관 및 개인정보취급방침,
          회사가 별도로 정한 지침등에 의합니다.
        </div>
        <div className="index">제3조 (서비스 내용 및 요금)</div>
        <div className="content">
          1. 회사는 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를
          전달 받아 아래와 같은 위치기반서비스를 제공합니다.
          <br />
          가) Leaf의 위치를 활용한 에너지 절감 데이터 제공 : Leaf를 사용하고자
          할 때 등록한 위치정보를 활용하여 설치공간의 에너지 절감 데이터를
          제공합니다.
          <br />
          나) 이용자 위치를 활용한 에너지 절감 정보 제공 : Leaf를 설치하고
          사용하고자 할 때 어플리케이션 등록을 통한 위치 설정 및 이를 활용하여
          설치된 공간의 에너지 절감 데이터를 제공합니다.
          <br />
          다) 이용자 위치를 활용한 광고정보 제공 : 서비스 이용 과정에서
          개인위치정보주체 또는 이동성 있는 기기의 위치를 이용하여 광고소재를
          제시합니다.
          <br />
          2. 제1항 위치기반 서비스의 이용요금은 무료입니다.
        </div>
        <div className="index">제4조 (위치정보 수집방법)</div>
        <div className="content">
          1. 회사는 다음과 같은 방식으로 개인위치정보를 수집합니다.
          <br />
          가) GPS칩이 내장된 전용 단말기를 통해 수집되는 GPS정보를 통한 위치정보
          수집
        </div>
        <div className="index">제5조 (개인위치정보주체의 권리)</div>
        <div className="content">
          1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중
          일부 또는 개인위치정보의 이용·제공목적, 제공받는 자의 범위 및 위치기반
          서비스의 일부에 대하여 동의를 유보할 수 있습니다.
          <br />
          2. 개인위치정보주체는 개인위치정보의 수집·이용·제공의 일시적인 중지를
          요구할 수 있습니다. 이 경우 회사는 요구를 거절하지 아니하며, 이를 위한
          기술적 수단을 갖추고 있습니다.
          <br />
          3. 개인위치정보주체는 언제든지 개인위치정보의 수집·이용·제공의
          일시적인 중지를 요구할 수 있습니다. 이 경우 회사는 요구를 거절하지
          아니하며, 이를 위한 기술적 수단을 갖추고 있습니다.
          <br />
          4. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를
          요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수
          있습니다. 이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
          <br />
          가) 개인위치정보주체에 대한 위치정보 수집·이용·제공사실 확인자료
          <br />
          나) 개인위치정보주체의 개인위치정보가 위치정보의 보호 및
          이용등에관한법률또는다른법령의규정에의하여제3자에게 제공된이유및내용
          <br />
          5. 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는
          지체 없이 수집된 개인위치정보 및 위치정보 수집·이용·제공사실
          확인자료를 파기합니다. 단, 동의의 일부를 철회하는 경우에는 철회하는
          부분의 개인위치정보 및 위치정보 수집·이용·제공사실 확인자료에
          한합니다.
          <br />
          6. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관
          제12조의 연락처를 이용하여 회사에 요구할 수 있습니다.
        </div>
        <div className="index">제6조 (법정대리인의 권리)</div>
        <div className="content">
          1. 회사는 만 14세 미만 아동으로부터 개인위치정보를 수집·이용 또는
          제공하고자 하는 경우에는 만 14세 미만 아동과 그 법정대리인의 동의를
          받아야 합니다.
          <br />
          2. 법정대리인은 만 14세 미만 아동의 개인위치정보를 수집·이용·제공에
          동의하는 경우 동의유보권, 동의철회권 및 일시중지권, 열람·고지요구권을
          행사 할 수 있습니다.
        </div>
        <div className="index">
          제7조 (위치정보 이용·제공 사실 확인자료 보유근거 및 보유기간)
        </div>
        <div className="content">
          1. 회사는위치정보의보호및이용등에관한법률제16조제2항에 근거하여 개인
          위치정보주체에 대한 위치정보 수집·이용·제공 사실 확인자료를
          위치정보시스템에 자동으로 기록하며, 1년 이상 보관합니다.
        </div>
        <div className="index">제8조 (서비스의 변경 및 중지)</div>
        <div className="content">
          1. 회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반
          사정또는법률상의장애등으로서비스를유지할수없는경우, 서비스의 전부 또는
          일부를제한, 변경하거나 중지할 수 있습니다.
          <br />
          2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
          공지하거나 개인위치정보주체에게 통지합니다.
        </div>
        <div className="index">
          제9조 (개인위치정보 제3자 제공 시 즉시 통보)
        </div>
        <div className="content">
          1. 회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의
          개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를
          제공하는 경우에는 제공받는 자 및 제공목적을 사전에
          개인위치정보주체에게 고시하고 동의를 받습니다.
          <br />
          2. 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3장에게
          제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회
          개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시
          통보합니다.
          <br />
          3. 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여
          지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
          <br />
          가) 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
          수신기능을 갖추지 아니한 경우
          <br />
          나) 개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의
          통신 단말장치 또는 전자우편주소 등오로 통보할 것을 미리 요청한 경우
        </div>
        <div className="index">제 10조 (손해배상)</div>
        <div className="content">
          1. 개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
          제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
          대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이
          없음을 입증하지 아니하면 책임을 면할 수 없습니다.
        </div>
        <div className="index">제 11조 (분쟁의 조정)</div>
        <div className="content">
          1. 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가
          이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에
          재정을 신청할 수 있습니다.
          <br />
          2. 회사 또는 개인정보위치 주체는 위치정보와 관련된 분쟁에 대해
          당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
          개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을 신청할 수
          있습니다.
        </div>
        <div className="index">제 12조 (사업자 정보)</div>
        <div className="content">
          회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
          <br />
          상호 : (주)씨드앤
          <br />
          주소 : 서울특별시 성동구 왕십리로 115, 6층 605, 606, 607호
          (성수동1가)(성수동1가, 헤이그라운드 서울숲점)
          <br />
          개인정보 보호책임자(CPO) : 홍원진
          <br />
          이메일주소 : seedn@seedn.co.kr
          <br />
          부칙 본 약관은 2017년 12월 8일부터 적용됩니다.
        </div>
      </PrivateBlock>
    </>
  );
};

export default Private;
